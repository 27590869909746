import React from "react";
import List from "../../Assets/MainPage/list.png";
import Group from "../../Assets/MainPage/Group.png";
import { Link } from "react-router-dom";

function MainPage() {
  return (
    <div className="py-4 px-2 h-100">
      <div className="text-3xl font-bold text-center">
        Effortless Verification, Instant Access.
      </div>
      <div className="text-sm pt-5 text-center font-medium leading-6">
        Welcome to SGIA, where your convenience is our priority. Streamline your
        experience with our QR code verification system. Scan your code, and
        unlock a world of seamless bookings and exclusive memberships instantly.
      </div>
      <div className="text-sm text-center font-medium  leading-6">
        {" "}
        Your journey begins here!
      </div>
      <div className="pt-[67px] flex flex-col gap-10">
        <Link to="/QR?type=bookings">
          <div className="bg-[#268EFF] flex flex-row py-[36px] pr-[36px] pl-[17px] rounded-lg text-left gap-10">
            <div>
              <div className="text-white font-extrabold text-[17px] pb-[6px]">
                Verify Bookings
              </div>
              <div className="text-white font-light text-sm">
                Scan QR code to verify & see details of bookings
              </div>
            </div>
            <div>
              <img className="opacity-25" src={List} alt="" />
            </div>
          </div>
        </Link>
        <Link to="/QR?type=membership">
        <div>
          <div className="bg-[#39876D] flex flex-row py-[36px] pr-[36px] pl-[17px] rounded-lg text-left gap-10">
            <div>
              <div className="text-white font-extrabold text-[17px] pb-[6px]">
                Verify Membership
              </div>
              <div className="text-white font-light text-sm">
                Scan QR code to verify & see details of membership
              </div>
            </div>
            <div>
              <img className="opacity-90" src={Group} alt="" />
            </div>
          </div>
        </div>
        </Link>
      </div>
    </div>
  );
}

export default MainPage;
