import React from "react";

import Logo from "../../Assets/Login/logo.svg";

import { Form, Input, Button } from "antd";
import { MailOutlined, LockOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

function Password() {
  return(<div className="pt-10 px-5">
    <div className="flex justify-center w-36 h-auto m-auto">
      <img src={Logo} style={{
        width:"100%"
      }} />
    </div>
    <div className="text-center pt-3 pb-16">Change Password</div>
    <Form>
      <Form.Item>
        <Input.Password
          style={{ color: "#E0E0E0", padding: "15px" }}
          prefix={<LockOutlined />}
          placeholder="Password"
        />
      </Form.Item>
      <Form.Item>
        <Input.Password
          style={{ color: "#E0E0E0", padding: "15px" }}
          prefix={<LockOutlined />}
          placeholder="Confirm Password"
        />
      </Form.Item>
      <div className="flex flex-col">
        <Button type="primary" danger>
          Confirm
        </Button>
      </div>
    </Form>
  </div>)
}

export default Password;
