import React, { useEffect, useState, useRef } from "react";
import Webcam from "react-webcam";
import { CloseOutlined } from "@ant-design/icons";
import Click from "../../Assets/QR/Group 20.png";
import Gallery from "../../Assets/QR/image-gallery_3342176 1.png";
import jsQR from "jsqr";
import { useNavigate } from "react-router";
import SiaApi from "../../utils/SiaApi";
import { QRCode, message } from "antd";
import QrScanner from "qr-scanner";

function QR() {
  const [data, setData] = useState();
  const webcamRef = React.useRef(null);
  const ref = useRef(null);
  const query = new URLSearchParams(window.location.search);
  const type = query.get("type");
  const [selectedImage, setSelectedImage] = useState(null);
  const [qrOn, setQrOn] = useState(true);
  const videoEl = useRef(null);
  const qrBoxEl = useRef(null);
  const scanner = useRef();
  const navigate = useNavigate();
  const [scannedResult, setScannedResult] = useState("");
  useEffect(() => {
    if (!type) {
      navigate("/");
    }
  }, [type, navigate]);

  const handleImageChange = (e) => {
    setSelectedImage(e.target.files[0]);
    setData(URL.createObjectURL(e.target.files[0]));
  };

  const onScanFail = (error) => {};

  const onScanSuccess = (result) => {
    getData(result?.data);
  };

  useEffect(() => {
    if (videoEl?.current && !scanner.current) {
      scanner.current = new QrScanner(videoEl?.current, onScanSuccess, {
        onDecodeError: onScanFail,

        preferredCamera: "environment",

        highlightScanRegion: true,

        highlightCodeOutline: true,

        overlay: qrBoxEl?.current || undefined,
      });

      scanner?.current
        ?.start()
        .then(() => setQrOn(true))
        .catch((err) => {
          if (err) setQrOn(false);
        });
    }

    return () => {
      if (!videoEl?.current) {
        scanner?.current?.stop();
      }
    };
  }, []);

  useEffect(() => {
    if (!qrOn)
      message.error(
        "Camera is blocked or not accessible. Please allow camera in your browser permissions and Reload."
      );
  }, [qrOn]);

  const getData = async (data) => {
    console.log("yes here");
    if (type === "bookings") {
      try {
        const res = await SiaApi.post(`/bookings/search?id=${data}`, {
          Authorization: localStorage.getItem("token"),
        });
        navigate(`/event?bookingId=${data}&type=${res.data.result[0].type}`);
      } catch (err) {
        console.log(err);
        navigate(`/error?type=${type}`);
        // message.error("No such booking found with this QR code");
      }
    } else if (type === "membership") {
      try {
        const res = await SiaApi.post(
          `members/get-member-details`,
          {
            id: data,
          },
          {
            Authorization: localStorage.getItem("token"),
          }
        );
        navigate(`/member?membershipId=${data}`);
      } catch (err) {
        navigate(`/error?type=${type}`);
        // message.error("No such membership found with this QR code");
      }
    }
  };

  const capture = React.useCallback(() => {
    if (videoEl.current) {
      setData(videoEl.current.getScreenshot());
    }
  }, [videoEl]);

  useEffect(() => {
    const scanQRCode = async () => {
      if (data) {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");
          canvas.width = img.width;
          canvas.height = img.height;
          ctx.drawImage(img, 0, 0);

          const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);

          const code = jsQR(imageData.data, canvas.width, canvas.height, {
            inversionAttempts: "dontInvert", // Try to avoid inverting the QR code
          });

          if (code) {
            // Handle the QR code data as needed
            getData(code.data);
            console.log("Scanned QR Code:", code.data);
          } else {
            navigate(`/error?type=${type}`)
            console.log("No QR code found in the image.");
          }
        };
        img.src = data;
      }
    };

    scanQRCode();
  }, [data]);

  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    // Update dimensions when the window is resized
    const handleResize = () => {
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const videoConstraints = {
    width: dimensions.width,
    height: dimensions.height,
    // facingMode: "user",
    facingMode: { exact: "environment" },
  };

  return (
    <div style={{ position: "relative" }}>
      <div className="bg-black" style={{ height: "100%" }}>
        {!data ? (
          <>
            <video ref={videoEl} className="h-[70vh]"></video>
          </>
        ) : (
          <img style={{ height: "70vh" }} src={data} alt="Captured QR Code" />
        )}
      </div>

      <div
        className="p-4"
        style={{
          borderTopLeftRadius: "10px",
          borderTopRightRadius: "10px",
          position: "absolute",

          right: "0",
          left: 0,
          bottom: "-50px",
          background: "white",
          textAlign: "center",
        }}
      >
        <div>QR Code Scan</div>
        <div className="flex justify-between items-center">
          <div
            onClick={() => {
              if (!data && !selectedImage) {
                navigate("/");
              }
              setData(null);
              setSelectedImage(null);
              ref.current.value = null;
            }}
          >
            <CloseOutlined style={{ fontSize: "20px" }} />
          </div>
          {/* <div><img src={Click} alt="Capture" onClick={capture} /></div> */}
          <div>
            <img
              src={Gallery}
              alt="Open Gallery"
              onClick={() => document.getElementById("fileInput").click()}
            />
            <input
              id="fileInput"
              type="file"
              accept="image/*"
              ref={ref}
              onChange={handleImageChange}
              style={{ display: "none" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default QR;
