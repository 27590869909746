import React, { useEffect, useState } from "react";
import Register from "../../../Assets/Membership/register.png";
import { Form, Input, Select, Button } from "antd";
import SiaApi from "../../../utils/SiaApi";
import { useLocation, useNavigate } from "react-router";
import SuccessModal from "../../../Components/SuccessModal";
import ErrorModal from "../../../Components/ErrorModal";
import UserImage from "../../../Assets/Membership/User.png"

function RegisterNewGuest() {
  const [identity, setIdentity] = useState("ic_number");
  const location = useLocation();
  const navigate = useNavigate();
  // const [countryCode,setCountryCode] =useState("+65");
  const [errorMessage,setErrorMessage] = useState("This guest exceeded the quota!");
  const [visible1,setVisible1] = useState(false);
  const [visible2,setVisible2] = useState(false);
  const [guestHistory,setGuestHistory] = useState([]);
  const onFinish = async (values) => {
    try {
      const res = await SiaApi.post(
        "/guest/new-register",
        {
          ...values,
          member_id:location.state.membershipId,
          // country_code:countryCode,
          membership_plan_id: location.state.membershipPlanId,
        },
        {
          Authorization: localStorage.getItem("token"),
        }
      );
      if(res.data?.status){
        setVisible1(true);
      }else{
        setVisible2(true);
        setErrorMessage(res.data.message);
      }
        
    } catch (err) {
      console.log(err);
    }
  };
  const [form] = Form.useForm();

  const getGuestData = async () => {
    try {
      const { data } = await SiaApi.post(
        `/guest/history`,
        {
          member_id: location.state.membershipId,
        },
        {
          Authorization: localStorage.getItem("token"),
        }
      );
      console.log(data.result.data);
      setGuestHistory(data.result.data);
    } catch (err) {
      console.log(err);
    }
  }

  const handleForm = (id) => {
    const guest = guestHistory.find(item => item.id === id);
    form.setFieldsValue({
      name: guest.name,
      age: guest.age,
      email: guest.email,
      phone: guest.phone,
      type: guest.type,
      ic_number: guest.ic_number,
      passport: guest.passport,
    })
    setIdentity(guest.ic_number ? "ic_number" : "passport");
    // setCountryCode(guest.country_code);
  }
  useEffect(()=>{
    if(!location.state || !location.state.membershipId || !location.state.membershipPlanId){
      navigate("/");
    }
    getGuestData();
  },[])

  return (
    <div className="py-10 px-3">
      <div className="flex items-center gap-3 mb-5">
        <div>
          <img src={Register} alt="Register" />
        </div>
        <div>Register New Guest</div>
      </div>
      <Form form={form} onFinish={onFinish}>
        <Form.Item
          name="name"
          label="Name"
          rules={[
            {
              required: "true",
              message: "Please Enter Name",
            },
          ]}
        >
          <Input placeholder="Name" />
        </Form.Item>
        <div style={{padding:"20px 10px",gap:"13px",overflow:"auto",display:"flex",background:"#F5F5FA",alignItems:"center"}}>
          {
            guestHistory.map((item,index)=>{
              return(
                <div key={index} className="flex bg-white rounded-2xl gap-3" onClick={()=>handleForm(item.id)} style={{minWidth:"40%",cursor:"pointer", padding:"3px 8px",border: "1px solid var(--Chip-Border---Default, #DDDDE3)"}}>
                 
                  <img src={UserImage} alt="User" style={{width:"20px",height:"20px",borderRadius:"50%"}}/>
                  
                  <div style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{item.name}</div>
                </div>
              )
            })
          }
        </div>
        {/* <Form.Item
          name="age"
          label="Age"
          rules={[
            {
              required: "true",
              message: "Please Enter Age",
            },
          ]}
        >
          <Input
            placeholder="Age"
          />
        </Form.Item> */}
        {/* <Form.Item
          name="email"
          label="Email"
          rules={[
            {
              required: "true",
              message: "Please Enter Email",
            },
          ]}
        >
          <Input placeholder="Email" />
        </Form.Item> */}
        {/* <Form.Item
          name="phone"
          label="Phone"
          rules={[
            {
              required: "true",
              message: "Please Enter Phone",
            },
          ]}
        >
          <Input
            addonBefore={
              <Select value={countryCode} onChange={(e)=>setCountryCode(e)}>
                <Select.Option value="+91">+91</Select.Option>
                <Select.Option value="+65">+65</Select.Option>
              </Select>
            }
            placeholder="Phone"
          />
        </Form.Item> */}
        <Form.Item
          name="type"
          label="Relation"
          rules={[
            {
              required: "true",
              message: "Please Enter Relation",
            },
          ]}
        >
          <Select placeholder="Select Relation" >
            <Select.Option value="guest">Guest</Select.Option>
            <Select.Option value="children">Children</Select.Option>
            <Select.Option value="spouse">Spouse</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label="Identity">
          <Select value={identity} onChange={(e) => setIdentity(e)}>
            <Select.Option value="ic_number">NRIC</Select.Option>
            <Select.Option value="passport">Passport</Select.Option>
          </Select>
        </Form.Item>
        {identity === "ic_number" && (
          <Form.Item
            name="ic_number"
            label="NRIC"
            rules={[
              {
                required: identity === "ic" ? "true" : "false",
                message: "Please Enter NRIC",
              },
            ]}
          >
            <Input placeholder="NRIC" />
          </Form.Item>
        )}
        {identity === "passport" && (
          <Form.Item
            name="passport"
            label="Passport"
            rules={[
              {
                required: identity === "passport" ? "true" : "false",
                message: "Please Enter Passport",
              },
            ]}
          >
            <Input placeholder="Passport" />
          </Form.Item>
        )}
        <Form.Item>
          <div className="text-center">
            <Button
              style={{ width: "100%" }}
              htmlType="submit"
              type="primary"
              danger
            >
              Save
            </Button>
          </div>
        </Form.Item>
      </Form>
      <SuccessModal visible={visible1} setVisible={setVisible1} />
      <ErrorModal visible={visible2} setVisible={setVisible2} message={errorMessage}/>
    </div>
  );
}

export default RegisterNewGuest;
