import React, { useContext } from 'react'
import LoginView from './Views/LoginView'
import HomeView from './Views/HomeView'
import MyContext from './utils/MyContext'

function AppView() {
    const {user} = useContext(MyContext)
    if(!user){
        return <LoginView/>
    }
    else{
        return <HomeView/>
    }
}

export default AppView