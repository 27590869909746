import React from "react";
import Navbar from "../Components/Navbar";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import MainPage from "./MainPage/MainPage";
import Event from "./Event/Event";
import Error from "../Components/Error";
import Membership from "./Membership/Membership";
import RegisterNewGuest from "./Membership/RegisterNewGuest/RegisterNewGuest";
import GuestHistory from "./Membership/ViewGuestHistory/GuestHistory";
import QR from "./QR/QR";
import ScrollToTop from "../utils/ScrollToTop";

function HomeView() {
  return (
    <Router>
      <Navbar />
      <ScrollToTop>
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/event" element={<Event/>} />
        <Route path="/QR" element={<QR/>}/>
        <Route path="/error" element={<Error/>} />
        <Route path="/member" element={<Membership/>} />
        <Route path="/register" element={<RegisterNewGuest/>}/>
        <Route path="/guest" element={<GuestHistory/>}/>
        <Route path="*" element={ <Navigate to="/" /> }/>
      </Routes>
      </ScrollToTop>
    </Router>
  );
}

export default HomeView;
