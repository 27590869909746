import React from "react";
import Logo from "../../Assets/Login/logo.svg";

import { Form, Input, Button } from "antd";
import { MailOutlined, LockOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

function Email() {
  return (
    <div>
      <div className="pt-10 px-5">
      <div className="flex justify-center w-36 h-auto items-center m-auto">
      <img src={Logo} style={{
        width:"100%"
      }} />
    </div>
        <div className="text-center pt-3 pb-16">Forgot Password?</div>
        <Form>
          <Form.Item>
            <Input
              style={{ color: "#E0E0E0", padding: "15px" }}
              prefix={<MailOutlined />}
              placeholder="Email Id"
            />
          </Form.Item>
          <div className="flex flex-col">
            <Button type="primary" danger>
              Reset Password
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default Email;
