import React from 'react'
import User from "../../../Assets/Membership/User.png"
import {Divider } from "antd"
import { capitalizeFirstLetter } from '../../../utils/Captalizeletter'

function Guest({item}) {
  return (
    <div>
         <div className="my-5 mx-1 border-[1px] border-[#EFEEEA] rounded-2xl">
        <div className='flex flex-row items-center gap-4 py-[26px] px-[10px]'>
          <img width={30} height={30} src={User} />
          <div className='font-bold text-lg'>{item.name}</div>
        </div>
        <div className='divide'>
        <Divider />
        </div>
        <div className="py-[30px] px-[10px] flex flex-col gap-[6px]">
            <div className="flex justify-between align-middle p-2">
              <div className="text-[#98A2B3]">Email Id</div>
              <div>{item.email}</div>
            </div>
            <div className="flex justify-between align-middle p-2">
              <div className="text-[#98A2B3]">Phone</div>
              <div>({item.country_code}) {item.phone}</div>
            </div>
            <div className="flex justify-between align-middle p-2">
              <div className="text-[#98A2B3]">Relation</div>
              {item.type && <div>{capitalizeFirstLetter(item.type)}</div>}
            </div>
            <div className="flex justify-between align-middle p-2">
              <div className="text-[#98A2B3]">Identity</div>
              <div>{item.passport?"Passport":"IC"}</div>
            </div>
            <div className="flex justify-between align-middle p-2">
              <div className="text-[#98A2B3]">{item.passport?"Passport Number":"IC Number"}</div>
              <div>{item.passport?item.passport:item.ic_number}</div>
            </div>
            <div className="flex justify-between align-middle p-2">
              <div className="text-[#98A2B3]">Status</div>
              <div className='text-white px-3 py-2 bg-green-500 rounded-md'>Success</div>
            </div>
          </div>
      </div>
    </div>
  )
}

export default Guest