import React from 'react'
import Coin from "../../../Assets/Membership/money.png"

function DuePayment({color,name,price,id}) {
  return (
    <>
        <div className='rounded-md bg-[#F9F9F9] flex  py-3 px-2 items-center'>
            <div style={{width:"15%"}}>   
                <img style={{background:color, padding:"10px",borderRadius:"50px"}} src={Coin} />
            </div>
            <div style={{width:"55%"}} className='text-left'>
                <div className='font-medium'>{name}</div>
                <div className='text-[#c4c4c4]'>{id}</div>
            </div>
            <div  style={{width:"30%"}}>
                <div className='text-[#ed2939] font-bold'>{price}</div>
                <div className='text-[#c4c4c4]'>Due Amount</div>
            </div>
        </div>
    </>
  )
}

export default DuePayment