import React, { useContext, useState } from "react";
import { Button, Drawer, message } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import Logo from "../Assets/Login/logo.svg";
import { Link } from "react-router-dom";
import MyContext from "../utils/MyContext";

function Navbar() {
  const [visible, setVisible] = useState(false);
  const {user,setUser} = useContext(MyContext)
  const onClose = () => {
    setVisible(false);
  };
  const showDrawer = () => {
    setVisible(true);
  };
  return (
    <div>
      <div className="flex justify-between px-5 py-3">
        <div className="w-36 h-auto">
          <img width={76} height={39} src={Logo} className="logo" alt="" />
        </div>
        <div>
          <Button onClick={showDrawer} className="border-none ">
            <MenuOutlined style={{ color: "#F31237", fontSize: "16px" }} />
          </Button>
        </div>
      </div>
      <div style={{ textAlign: "center" }}>
        <Drawer
          placement="right"
          width="100vw"
          onClose={onClose}
          getContainer={false}
          visible={visible}
        >
          <p onClick={onClose} className="text-lg">
            <Link
              onClick={() => {
                localStorage.removeItem("token");
                setUser(null)
                message.success('Logged Out Successfully');
              }}
              to="/login"
            >
              Log Out
            </Link>
          </p>
        </Drawer>
      </div>
    </div>
  );
}

export default Navbar;
