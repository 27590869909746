import React from 'react'
import Login from './Login/Login'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { Navigate } from 'react-router-dom';
import Email from './Login/Email';
import Password from './Login/Password';

function LoginView() {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login/>} />
        <Route path="*" element={ <Navigate to="/login" /> }/>
        <Route path="/email" element={<Email/>}/>
        <Route path="/password" element={<Password/>}/>
      </Routes>
    </Router>
  )
}

export default LoginView