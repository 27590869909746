import React, { useContext } from "react";
import Logo from "../../Assets/Login/logo.svg";

import { Form, Input, Button, message } from "antd";
import { MailOutlined, LockOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import SiaApi from "../../utils/SiaApi";
import MyContext from "../../utils/MyContext";

function Login() {
  const [form] = Form.useForm();
  const { user, setUser } = useContext(MyContext);
  const navigate = useNavigate();

  const onFinish = async (values) => {
    try {
      const res = await SiaApi.post("/login", values);
      if (res.status === 200) {
        message.success("Logged In Successfully");
        localStorage.setItem("token", "bearer " + res.data.token.token);
        navigate("/");
        setUser("bearer " + res.data.token.token);
        SiaApi.defaults.headers.Authorization = "bearer " + res.data.token.token;
        return;
      }
      message.error("Wrong email or password");
    } catch (err) {
      message.error("Wrong email or password");
    }
  };
  return (
    <div className="pt-10 px-5">
        <div className="flex justify-center w-36 h-auto items-center m-auto">
      <img src={Logo} style={{
        width:"100%"
      }} />
    </div>
      <div className="text-center pt-3 pb-16">Log In to your SIA account!</div>
      <Form form={form} onFinish={onFinish}>
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              message: "Please enter your email id",
            },
          ]}
        >
          <Input
            style={{ color: "#E0E0E0", padding: "15px" }}
            prefix={<MailOutlined />}
            placeholder="Email Id"
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: "Please enter your password",
            },
          ]}
        >
          <Input.Password
            style={{ color: "#E0E0E0", padding: "15px" }}
            prefix={<LockOutlined />}
            placeholder="Password"
          />
        </Form.Item>
        <Form.Item>
          <div className="flex flex-col">
            <Button htmlType="submit" type="primary" danger>
              Log In
            </Button>
          </div>
        </Form.Item>
        <div className="text-center">
          <Link
            style={{
              textDecoration: "underline",
              color: "#007AFF",
              textAlign: "center",
              marginTop: "20px",
            }}
          >
            Forgot your password?
          </Link>
        </div>
      </Form>
    </div>
  );
}

export default Login;
