import "./App.css";
import AppView from "./AppView";
import { useState } from "react";
import MyContext from "./utils/MyContext";

function App() {
  const [user, setUser] = useState(localStorage.getItem("token"));
  return (
    <MyContext.Provider value={{ user, setUser }}>
      <div className="App">
        <AppView />
      </div>
    </MyContext.Provider>
  );
}

export default App;
