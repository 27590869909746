import { Button, Modal } from "antd";
import React from "react";
import Error from "../Assets/Error 1.png";
import { useNavigate } from "react-router";

function ErrorModal({ visible, setVisible ,message}) {
  const navigate = useNavigate();
  return (
    <Modal
      visible={visible}
      centered
      footer={false}
      onCancel={() => {
        setVisible(false);
        navigate(-1);
      }}
    >
      <div className="flex flex-col justify-center items-center">
        <div>
          <img src={Error} />
        </div>
        <div>
          <div
            style={{
              fontSize: "28px",
              fontStyle: "normal",
              fontWeight: "800",
              lineHeight: "48px",
            }}
          >
            Alert!
          </div>
        </div>
        <div
          style={{
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "24px",
          }}
        >
          {message?message:"This guest exceeded the quota!"}
        </div>
        <Button
          onClick={() => {
            setVisible(false);
            navigate(-1);
          }}
          style={{
            borderRadius: "43px",
            background: "#1E73BE",
            color: "#fff",
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
            alignItems: "center",
            padding: "10px 32px",
          }}
        >
          Close
        </Button>
      </div>
    </Modal>
  );
}

export default ErrorModal;
