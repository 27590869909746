import React from "react";
import Success from "../Assets/success 1.png";
import { Button, Modal } from "antd";
import { useNavigate } from "react-router";

function SuccessModal({ visible, setVisible }) {
  const navigate = useNavigate();
  return (
    <Modal visible={visible} centered footer={false} onCancel={() => {setVisible(false)
    navigate(-1);
    }}>
      <div className="flex flex-col justify-center items-center">
        <div>
          <img src={Success} />
        </div>
        <div>
          <div style={{
            fontSize: "28px", 
            fontStyle: "normal",
            fontWeight: "800",
            lineHeight: "48px",
          }}>Success!</div>
        </div>
        <div style={{
            fontSize: "16px", 
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "24px",
          }}>Guest Registered Succesfully!</div>
        <Button onClick={()=>{
            navigate(-1);
        }} style={{
          borderRadius:"43px",
          background:"#1E73BE",
          color:"#fff",
          display:"flex",
          justifyContent:"center",
          marginTop:"20px",
          alignItems:"center",
          padding:"10px 32px"
        }}>Close</Button>
      </div>
    </Modal>
  );
}

export default SuccessModal;
