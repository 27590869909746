import { Button } from "antd";
import React, { useEffect, useState } from "react";
import ErrorLogo from "../Assets/Error/Error.png";
import { Link, useNavigate } from "react-router-dom";

function Error() {
  const navigate = useNavigate();
  const query = new URLSearchParams(window.location.search);
  const type = query.get("type");
  const [errorMessage, setErrorMessage] = useState({
    heading: "",
    description: "",
  });

  useEffect(() => {
    if (!type) {
      navigate("/");
    } else {
      if (type === "bookings") {
        setErrorMessage({
          heading: "Invalid Booking !",
          description:
            "Your scan QR Code shows invalid booking.Please click below to scan QR code again.",
        });
      } else {
        setErrorMessage({
          heading: "Invalid Membership !",
          description:
            "Your scan QR Code shows invalid membership.Please click below to scan QR code again.",
        });
      }
    }
  }, []);
  return (
    <div className="flex flex-col justify-center align-middle py-7">
      <div className="flex justify-center">
        <img src={ErrorLogo} alt="Error" />
      </div>
      <div className="font-bold text-xl text-center">
        {errorMessage.heading}
      </div>
      <div className="font-thin text-sm w-60 m-auto text-center">
        {errorMessage.description}
      </div>
      <div className="pt-10 m-auto">
        <Link to="/">
          <Button type="primary w-100" style={{ width: "90vw" }} danger>
            Scan QR Code Again
          </Button>
        </Link>
      </div>
    </div>
  );
}

export default Error;
