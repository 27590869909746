import { Carousel,Button, message, Empty } from "antd";
import React, { useEffect, useState } from "react";
import image from "../../Assets/Membership/image-profilepic.png";
import SIA from "../../Assets/Membership/SIA 1.png";
import IDcard from "../../Assets/Membership/idcard.svg";
import Money from "../../Assets/Membership/redColorMoney.png";
import { Divider } from "antd";
import DuePayment from "./DuePayment/DuePayment";
import SiaApi from "../../utils/SiaApi";
import { useNavigate } from "react-router";
import moment from "moment";
import { capitalizeFirstLetter } from "../../utils/Captalizeletter";

function Membership() {
  const query = new URLSearchParams(window.location.search);
  const membershipId = query.get("membershipId");
  const [membershipDetails,setMembershipDetails] = useState({
    first_name:"",
    email:"",
    country_code:"",
    phone:"",
    plan_name:"",
    status:"",
    expiry_date:"",
    activation_date:"",
    payment_paid:"",
    plan_id:"",
    image:""
  });
  const navigate = useNavigate();

  const fetchMembershipDetails = async () => {
    try{
      const res = await SiaApi.get(`members/qr/${membershipId}`,{
          Authorization:localStorage.getItem("token")
      });
      console.log(res.data.membersData)
      setMembershipDetails(res.data.membersData);
    }catch(err){
      console.log(err);
      message.error("No such membership found with this QR code");
      navigate("/");
    }
  }

  const checking = async () => {
    await fetchMembershipDetails();
  }

  const register = () =>{
    navigate(`/register`,{
      state:{
        membershipId:membershipId,
        membershipPlanId:membershipDetails.plan_id
      }
    });
  }
  useEffect(() => {
    if (!membershipId) {
      navigate("/");
    }
    else{
      checking();
    }
  },[]);

  const guest = () =>{
    navigate(`/guest`,{
      state:{
        membershipId:membershipId,
      }
    });
  }
  return (
    <div className="py-5">
      <Carousel className="carousel">
        <div>
          <div className="flex p-4 gap-3">
            <div style={{ width: "30%" }}>
              <img style={{ width: "100%", height: "100%" ,objectFit:"cover"}} src={membershipDetails.image} />
            </div>
            <div
              style={{ width: "60%" }}
              className="text-left flex flex-col gap-5"
            >
              <div>
                <div className="text-2xl font-bold">{membershipDetails.first_name}</div>
                <div>{membershipDetails.email}</div>
                <div>({membershipDetails.country_code}) {membershipDetails.phone}</div>
              </div>
              <div>
                <div className="text-xs">
                  {membershipDetails.plan_name} -
                  <span className={`${membershipDetails.status==="active"?"text-green-600":"text-red-600"}`}>{capitalizeFirstLetter(membershipDetails.status)}</span>
                </div>
                <div className="text-xs">Expired On {moment(membershipDetails.expiry_date).format("DD MMM YYYY")}</div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="bg-[#253458] flex justify-center align-middle p-5">
            <img src={SIA} style={{ height: "140px" }} />
          </div>
        </div>
      </Carousel>
      <div className="mt-10"></div>
      <Divider />
      <div className="px-3">
        <div>
          <div className="flex gap-3 items-center">
            <img width={30} height={30}  src={IDcard} /> Membership Details:
          </div>
          <div className="py-[26px] px-[10px] flex flex-col gap-[6px]">
            <div className="flex justify-between align-middle p-2">
              <div className="text-[#98A2B3]">Name</div>
              <div className="text-right">{membershipDetails.first_name}</div>
            </div>
            <div className="flex justify-between align-middle p-2">
              <div className="text-[#98A2B3]">Membership Plan</div>
              <div className="text-right">{membershipDetails.plan_name}</div>
            </div>
            <div className="flex justify-between align-middle p-2">
              <div className="text-[#98A2B3]">Membership UID</div>
              <div className="text-right">{membershipDetails.member_uid}</div>
            </div>
            <div className="flex justify-between align-middle p-2">
              <div className="text-[#98A2B3]">Email Id</div>
              <div className="text-right">{membershipDetails.email}</div>
            </div>
            <div className="flex justify-between align-middle p-2">
              <div className="text-[#98A2B3]">Membership Period</div>
              <div className="text-right">{moment(membershipDetails.activation_date).format("DD MMM YYYY")}  -  {moment(membershipDetails.expiry_date).format("DD MMM YYYY")}</div>
            </div>
            <div className="flex justify-between align-middle p-2">
              <div className="text-[#98A2B3]">Amount Paid</div>
              <div className="text-right">S$ {membershipDetails?.payments?.[0]?.total_amount ? membershipDetails?.payments?.[0]?.total_amount?.toFixed(2) :0.00}</div>
            </div>
            <div className="flex justify-between align-middle p-2">
              <div className="text-[#98A2B3]">Payment Status</div>
              <div className={`rounded-md  text-white px-4 ${membershipDetails?.membership_histories?.payment_status==="paid"?"bg-[#028C5C]":"bg-red-400"} `} style={{textTransform:"uppercase"}}>{membershipDetails?.membership_histories?.is_refunded? "Refunded": membershipDetails?.membership_histories?.payment_status ? membershipDetails?.membership_histories?.payment_status : "Pending"}</div>
            </div>
          </div>
        </div>
      </div>
      <Divider />
      <div className="px-3">
        <div>
          <div className="flex gap-3 items-center mb-4">
            <img width={30} height={30} src={Money} /> Due Payments:
          </div>
          <div className="flex flex-col gap-5">
            {
                 
                 
                   membershipDetails?.membership_histories?.payment_status===null ?
                  (  <><DuePayment color={"#FFBA34"} name={membershipDetails?.membership_plan?.name} price={`S$ ${membershipDetails?.membership_plan?.amount}`} id={membershipDetails?.membership_plan?.id} />
                    </> 
                  ) 
                 :(
                  <div><Empty/></div>
                )
            }
            </div>
        </div>
      </div>
      <Divider/>
      <div className="flex flex-col gap-5 w-80 m-auto">
      <Button type="primary" danger onClick={register}>Register New Guest</Button>
      <Button onClick={guest} >View Guest History</Button>
      </div>
    </div>
  );
}

export default Membership;


const data = [
    // {
    //     color:"#FFBA34",
    //     name:"New Signup Male (Ordinary - Indian)",
    //     price:"S$ 32.00",
    //     id:575849959
    // },
    // {
    //     color:"#AE7FFD",
    //     name:"Indian Folk Dance Festival",
    //     price:"S$ 100.00",
    //     id:5758412959

    // },
    // {
    //     name:"Diwali-2023",
    //     color:"#FF6868",
    //     price : "S$ 54.00",
    //     id:3123213
    // }
]
