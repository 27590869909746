import React, { useEffect, useState } from 'react'
import Register from "../../../Assets/Membership/register.png";

import Guest from './Guest';
import { Empty, message } from 'antd';
import SiaApi from '../../../utils/SiaApi';
import { useLocation, useNavigate } from 'react-router';

function GuestHistory() {
  const location = useLocation();
  const navigate = useNavigate();
  const [guestHistory,setGuestHistory] = useState([]);

  const getData = async () => { 
    try{
      const response = await SiaApi.post("/guest/history",{
        member_id:location.state.membershipId,
      },{
        Authorization: localStorage.getItem("token"),
      });
      console.log(response.data.result.data);
      let temp = [];
      for(let i = response.data.result.data.length-1;i>=0;i--){
        temp.push(response.data.result.data[i])
      }
      setGuestHistory(temp);

    }catch(err){
      console.log(err);
      message.error("Something went wrong");
    }
  }

  useEffect(()=>{
    if(!location.state || !location.state.membershipId){
      navigate("/");
    }
    getData();
  },[])

  return (
    <div className="py-10 px-3">
    <div className="flex items-center gap-3 mb-5">
      <div>
        <img src={Register} alt="Register" />
      </div>
      <div>Guest History</div>
    </div>
    {
      guestHistory.length>0 ? guestHistory.map((item,index)=>{
        return <Guest key={index} item={item}/>
      }):(
        <Empty/>
      )
    }
  </div>
  )
}

export default GuestHistory