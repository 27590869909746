import { message } from "antd";
import axios from "axios";
const SiaApi = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    "Authorization": localStorage.getItem("token"),
  },
});

SiaApi.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401 && window.location.pathname !== "/login") {
      localStorage.removeItem("token");
      message.error("Session expired, please login again");
      window.location.href = "/login";
    }
    throw error;
  }
);

export default SiaApi;
